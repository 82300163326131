import { useActiveGames } from '~/composables/game';

// responsible by find some org and set it
export default defineNuxtRouteMiddleware((to) => {
	const toOrgSlug = to.params.orgSlug;
	const toGameSlug = to.params.gameSlug;

	const activeGame = useSafeActiveGame();
	const games = useActiveGames();

	const noActiveGame = !activeGame.value;
	const gameNotFound = toGameSlug && noActiveGame;
	if (!toGameSlug || gameNotFound) {
		const gamesIsEmpty = !games.value || games.value.length === 0;
		if (!gamesIsEmpty) {
			const firstGame = games.value[0];
			const firstGameSlug = firstGame.slug;
			return navigateTo(`/app/${toOrgSlug}/${firstGameSlug}`);
		}
	}
});
